<template>
  <v-speed-dial v-model="fab" top left fixed direction="bottom" transition="scale-transition">
    <template v-slot:activator>
      <v-btn v-model="fab" style="background-color: rgba(128, 137, 173, 0.4)" light fab>
        <v-icon v-if="fab" color="white"> mdi-close </v-icon>
        <v-icon v-else color="white"> mdi-menu </v-icon>
      </v-btn>
    </template>
    <router-link to="/" style="text-decoration: none">
      <v-btn fab dark small style="background-color: rgba(128, 137, 173, 0.7)">
        <v-tooltip content-class="ml-3" color="rgba(128, 137, 173, 0.7)" right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-home </v-icon>
          </template>
          <span>Home</span>
        </v-tooltip>
      </v-btn>
    </router-link>
    <router-link v-if="cinelandia" to="/cinelandia" style="text-decoration: none">
      <v-btn fab dark small style="background-color: rgba(128, 137, 173, 0.7)">
        <v-tooltip content-class="ml-3" color="rgba(128, 137, 173, 0.7)" right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-movie-open </v-icon>
          </template>
          <span>Cinelandia</span>
        </v-tooltip>
      </v-btn>
    </router-link>
    <router-link to="/about" style="text-decoration: none">
      <v-btn fab dark small style="background-color: rgba(128, 137, 173, 0.7)">
        <v-tooltip content-class="ml-3" color="rgba(128, 137, 173, 0.7)" right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-map-marker-question-outline </v-icon>
          </template>
          <span>Chi siamo</span>
        </v-tooltip>
      </v-btn>
    </router-link>
  </v-speed-dial>
</template>

<script>
import moment from "moment";
import db from "../firebaseConfiguration/firebaseInit";

export default {
  data() {
    return {
      fab: false,
      cinelandia:false,
    };
  },
  created() {
    let currentDate = moment().format('YYYY-MM-DD');

    db.firestore()
        .collection('film')
        .where('startDate', '>=', currentDate)
        .where('cinelandia', '==', true)
        .orderBy('startDate')
        .get()
        .then((querySnapshot) => {
          this.cinelandia = querySnapshot.size > 0;
        });
  }
};
</script>

<style></style>
