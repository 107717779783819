<template>
  <div id="home_content">
    <menu-fab v-if="!$store.state.isMobile" />
    <div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <v-bottom-navigation
      v-if="$store.state.isMobile"
      class="elevation-0"
      dark
      fixed
      grow
      app
      shift
      style="background-color: rgb(40, 40, 40)"
      :value="
        $route.path === '/'
          ? 'home'
          : $route.path === '/cinelandia'
          ? 'cinelandia'
          : $route.path === '/dovesiamo'
          ? 'dovesiamo'
          : ''
      "
    >
      <v-btn to="/" value="home">
        <span>Home</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-btn to="/cinelandia" value="cinelandia">
        <span>Cinelandia</span>
        <v-icon>mdi-movie-open-outline</v-icon>
      </v-btn>
      <v-btn to="/about" value="dovesiamo">
        <span>Chi siamo?</span>
        <v-icon>mdi-map-marker-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import MenuFab from '../components/MenuFab';

export default {
  components: {
    MenuFab,
  },
  data() {
    return {
      activeNav: 0,
    };
  },
};
</script>

<style scoped>
#home_content {
  background-color: rgb(40, 40, 40);
  min-height: 100vh;
}
</style>
